import { Component, HostBinding, OnInit, ViewChildren, WritableSignal, inject, signal } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { AppService } from '@logic-suite/shared';
import { LanguageService } from '@logic-suite/shared/i18n';
import { IBreadCrumb } from '@logic-suite/shared/nav/breadcrumb';
import { TelemetryService } from '@logic-suite/shared/telemetry/telemetry.service';
import { getEnv, queryParamsToObject } from '@logic-suite/shared/utils';
import { Subscription, combineLatest, distinctUntilChanged, filter, map, timer } from 'rxjs';
import { CrumbLookupService } from './shared/crumb-lookup.service';
import { KeyboardActionHandler } from './shared/shortcuts/keyboard.directive';

export interface RouteHistoryItem {
  name: string;
  names?: string[];
  url: string;
  query?: Record<string, any>;
  svgIcon?: string;
  fontIcon?: string;
  index: number;
}

export interface RouteGroup {
  name: string;
  items: RouteHistoryItem[];
}

@Component({
    selector: 'bs-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent implements OnInit {
  private readonly updates = inject(SwUpdate);
  private readonly app = inject(AppService);
  private readonly telemetry = inject(TelemetryService);
  private readonly router = inject(Router);
  private readonly lookup = inject(CrumbLookupService);
  private readonly language = inject(LanguageService);

  subscriptions: Subscription[] = [];
  env = getEnv('env');

  @HostBinding('class.closed')
  isCollapsed = this.app.getItem('menu.isCollapsed');

  @HostBinding('class.framed')
  get inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  showKeyboardTooltips = false;
  version = '';

  history: WritableSignal<RouteHistoryItem[]> = signal([]);
  historyOpen = this.app.hasItem('menu.historyOpen') ? this.app.getItem('menu.historyOpen') : true;

  favorites: WritableSignal<RouteHistoryItem[]> = signal(
    this.app.getItem('menu.routeFavorites', []).filter((f: RouteHistoryItem | string) => typeof f !== 'string'),
  );

  menu!: RouteGroup[];
  @ViewChildren(MatTooltip) tooltips!: MatTooltip[];

  constructor() {
    let i = 1;
    this.menu = [
      {
        name: 'Customers',
        items: [
          { name: 'Customers', url: '/customer', svgIcon: 'customer', index: i++ },
          { name: 'Meteringpoints', url: '/meter', svgIcon: 'meter', index: i++ },
          { name: 'Grid Tariff', url: '/tariff', fontIcon: 'payments', index: i++ },
        ],
      },
      {
        name: 'Process',
        items: [
          { name: 'Invoicing', url: '/invoice', svgIcon: 'invoice', index: i++ },
          { name: 'Invoice corrections', url: '/corrections', fontIcon: 'price_check', index: i++ },
          { name: 'Tenant invoicing', url: '/tenant-invoice', fontIcon: 'receipt_long', index: i++ },
          { name: 'Tenant corrections', url: '/tenant-corrections', fontIcon: 'price_change', index: i++ },
        ],
      },
      // { name: 'Sandbox', items: [{ name: 'Tenant', url: '/tenant', icon: 'tenant' }] },
    ];

    // Make sure we run on an updated version
    if (this.updates.isEnabled) {
      // Ask user for permission to update once a new update is registered
      this.subscriptions.push(
        this.updates.versionUpdates
          .pipe(
            filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
            map((evt) => ({
              type: 'UPDATE_AVAILABLE',
              current: evt.currentVersion,
              available: evt.latestVersion,
            })),
          )
          .subscribe(() => {
            if (confirm('A newer version of the application is available. Load the new version?')) {
              this.updates.activateUpdate().then(() => document.location.reload());
            }
          }),
      );
      // Check every 5 minutes for updates
      this.subscriptions.push(timer(0, 5 * 60 * 1000).subscribe((n) => this.updates.checkForUpdate()));
    }

    this.app.getVersion().then((v) => (this.version = v || ''));
  }

  ngOnInit(): void {
    // Start telemetry
    this.telemetry.init();

    this.app.setApplicationID(20);

    // Compile route history
    const maxHistory = 5;
    this.history.set(
      this.app.getItem('menu.routeHistory', []).filter((f: RouteHistoryItem | string) => typeof f !== 'string'),
    );

    // Add current route to history
    this.subscriptions.push(
      combineLatest([
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)),
        this.app.titleChanged$.pipe(filter((t) => !!t)),
      ])
        .pipe(
          distinctUntilChanged(([prevNav, prevTitle], [currNav, currTitle]) => {
            return prevTitle === currTitle;
          }),
        )
        .subscribe(([event, title]) => {
          // We have a new route. Add it to the stack.
          const nav: NavigationEnd = event as NavigationEnd;

          const menuTitle = title.split(' | ').slice(-2, -1).join('');
          const menu = this.menu.reduce((acc, group) => [...acc, ...group.items], [] as RouteHistoryItem[]);
          const menuItem = menu.find((i) => i.name === menuTitle);
          const names = title.split(' | ').slice(0, -1).reverse();
          const [url, param] = nav.urlAfterRedirects.split('?');
          const item = Object.assign({}, menuItem, {
            name: names.join(' | '),
            names,
            url,
            query: Object.assign(url.split('/').length > 2 ? { h: 1 } : {}, queryParamsToObject(param)),
          });
          if (item.name && item.name != 'undefined' && this.favorites().findIndex((f) => f.url === item.url) === -1) {
            const exist = this.history().findIndex((h) => h.url === item.url);
            if (exist > -1) {
              // Move existing item to the top
              const hist = this.history();
              const existingItem = hist.splice(exist, 1)[0];
              hist.unshift(existingItem);
              this.history.set(hist);
            } else {
              // New item! Add to top
              this.history().unshift(item);
              this.history.set(this.history().slice(0, maxHistory));
            }
            // Remove duplicates
            this.history.set(
              Object.values(
                this.history().reduce((acc, curr) => {
                  acc[curr.url] = curr;
                  return acc;
                }, {} as any),
              ),
            );
            this.app.replaceItem('menu.routeHistory', this.history());
          }
        }),
    );
  }

  setLanguage($event: string) {
    return this.language.change($event);
  }

  toggleMenu() {
    this.isCollapsed = !this.isCollapsed;
    this.app.setItem('menu.isCollapsed', this.isCollapsed);
    setTimeout(() => window.dispatchEvent(new Event('resize')));
  }

  toggleHistory() {
    this.historyOpen = !this.historyOpen;
    this.app.setItem('menu.historyOpen', this.historyOpen);
  }

  addToFavorites(item: RouteHistoryItem, evt: MouseEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.favorites().findIndex((f) => f.url === item.url) === -1) {
      const favs = this.favorites();
      favs.unshift(structuredClone(item));
      this.favorites.set(favs);
      this.app.replaceItem('menu.routeFavorites', this.favorites());
      if (this.history().findIndex((f) => f.url === item.url) > -1) {
        this.history.set(this.history().filter((f) => f.url !== item.url));
        this.app.replaceItem('menu.routeHistory', this.history());
      }
    }
  }
  removeFavorite(item: RouteHistoryItem, evt: MouseEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.favorites.set(this.favorites().filter((f) => f.url !== item.url));
    this.app.replaceItem('menu.routeFavorites', this.favorites());
  }

  crumbTranslator() {
    return async (crumb: IBreadCrumb, index: number, path: IBreadCrumb[], original: IBreadCrumb[]) => {
      return await this.lookup.lookup(crumb, index, path, original);
    };
  }

  keyboardRoute(route: string): KeyboardActionHandler {
    return () => this.router.navigate([route]);
  }
}
